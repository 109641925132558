.short[data-v-80292f74] {
  text-align: left !important;
}
.long[data-v-80292f74] {
  text-align: right;
}
.half[data-v-80292f74] {
  min-width: 50%;
  max-width: 50%;
  vertical-align: middle;
  display: table-cell;
}
.half select[data-v-80292f74] {
  min-width: 100%;
  padding: 10px;
}
.second-half[data-v-80292f74] {
  padding: 0 20px;
}