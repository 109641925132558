.popup-content.more-padding[data-v-79946a29] {
  padding: 32px 20px 20px 20px;
}
.popup-content.more-padding[data-v-79946a29] a.close {
  top: 12px;
  right: 12px;
}
.popup-content[data-v-79946a29] {
  padding: 20px;
  text-align: center;
}
.popup-content[data-v-79946a29] h2 {
  color: #003cff;
  font-size: 2em;
  line-height: 1.21886em;
  margin-top: 0;
  font-weight: 600;
}
@media (min-width: 835px) {
.popup-content.more-padding[data-v-79946a29] {
    padding: 42px 54px 20px 54px;
}
.popup-content.more-padding[data-v-79946a29] a.close {
    top: 20px;
    right: 20px;
}
.popup-content[data-v-79946a29] h2 {
    font-size: 2.57143rem;
}
}