.box a[data-v-986606ee]:not(.flex-btn) {
  font-size: 1rem;
  font-weight: bold;
  font-family: "FSecureOffice";
}
.box h2[data-v-986606ee] {
  font-size: 1.28571rem;
  font-weight: 600;
  padding: 40px 0 16px 0;
  margin: 0;
  line-height: 1.21886em;
}
.box p[data-v-986606ee] {
  margin: 0;
  font-size: 1rem;
  padding: 0 0 16px;
  color: #666;
  line-height: 140%;
}
.box p + .flex-btn[data-v-986606ee] {
  margin: 10px 0 0 0;
}
@media (min-width: 835px) {
.box a[data-v-986606ee]:not(.flex-btn) {
    font-size: 1.14286rem;
}
.box h2[data-v-986606ee] {
    font-size: 1.57143rem;
    padding-bottom: 20px;
}
.box p[data-v-986606ee] {
    font-size: 1.14286rem;
    padding-bottom: 20px;
}
}