.coupon-share {
  padding-bottom: 46px;
}
.coupon-share.loading {
  height: 600px;
}
.coupon-share h2 {
  padding: 40px 0 20px 0;
  font-size: 1.28571rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.21886em;
}
.coupon-share h3 {
  font-weight: 600;
  font-size: 1.14286rem;
  padding: 0 0 16px 0;
  line-height: 1.375rem;
  margin: 0;
}
.coupon-share h4 {
  font-size: 1rem;
  padding: 0 0 16px 0;
  font-weight: 600;
  line-height: 1.21886em;
  margin: 0;
}
.coupon-share p {
  font-size: 1rem;
  padding: 0 0 16px;
  color: #666;
  line-height: 1.4rem;
  margin: 0;
}
.coupon-share picture {
  display: inline-block;
}
.coupon-share .purple {
  color: #6621cc;
}
.coupon-share .hint-box {
  background: #f5f5f5;
}
.coupon-share .hint-box picture {
  float: left;
  margin-top: 10px;
  margin-left: 8px;
}
.coupon-share .hint-box picture img {
  vertical-align: top;
}
.coupon-share .hint-box > .icon-spaced {
  margin: 0 0 20px 0;
  padding: 8px 10px 8px 46px;
}
.coupon-share .padded-icon > picture {
  width: 66px;
  float: right;
}
.coupon-share .padded-icon .content > .icon-spaced {
  padding-right: 82px;
}
.coupon-share ol {
  margin: 0 0 20px 0;
  padding: 0;
}
.coupon-share ol li {
  font-size: 1rem;
  list-style-position: inside;
  color: #666;
  padding-left: 5px;
  line-height: 140%;
  padding-bottom: 0.288em;
}
.coupon-share .voucher-grid {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 27px;
  grid-gap: 20px;
}
@media (min-width: 768px) {
.coupon-share {
    padding-bottom: 54px;
}
.coupon-share h2 {
    font-size: 1.57143rem;
    padding: 48px 0 20px 0;
}
.coupon-share h3 {
    font-size: 1.28571rem;
    padding: 0 0 10px 0;
    line-height: 1.567rem;
}
.coupon-share h4 {
    font-size: 1.14286rem;
    padding: 0 0 20px;
}
.coupon-share p {
    font-size: 1.14286rem;
    line-height: 1.6rem;
    padding: 0 0 20px;
}
.coupon-share .flex-btn {
    width: auto;
}
.coupon-share .hint-box {
    max-width: 572px;
}
.coupon-share .hint-box > picture {
    margin-top: 20px;
    margin-left: 17px;
}
.coupon-share .hint-box > .icon-spaced {
    padding: 10px 20px 10px 58px;
}
.coupon-share > .padded-icon > *:not(picture) {
    padding-left: 108px;
}
.coupon-share > .padded-icon .content > .icon-spaced {
    padding-right: 0;
}
.coupon-share > .padded-icon > picture {
    float: left;
    width: 88px;
}
.coupon-share ol {
    margin: 0 0 30px 0;
}
.coupon-share ol li {
    font-size: 1.14286rem;
    margin-left: 35px;
    list-style-position: outside;
}
.coupon-share .voucher-grid {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 46px;
}
}