.the-maintenance-title-section[data-v-a400c4d6] {
  padding: 16px;
}
.the-maintenance-title-section h1[data-v-a400c4d6] {
  font-size: 1.71429rem;
  color: #003cff;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
}
.the-maintenance-title-section p[data-v-a400c4d6] {
  font-weight: 300;
  text-align: center;
}
@media (min-width: 835px) {
.the-maintenance-title-section[data-v-a400c4d6] {
    padding: 32px;
}
.the-maintenance-title-section h1[data-v-a400c4d6] {
    margin-bottom: 40px;
}
.the-maintenance-title-section p[data-v-a400c4d6] {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
}