@media (min-width: 835px) {
.one-col-box[data-v-f95d32dc] .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 40px;
    grid-auto-rows: minmax(100px, auto);
    padding: 0 40px;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 835px), screen and (-ms-high-contrast: none) and (min-width: 835px) {
.one-col-box[data-v-f95d32dc] .content {
    display: -ms-grid;
    -ms-grid-columns: 1fr 40px 1fr;
    -ms-grid-rows: 1fr 0px 1fr;
}
.one-col-box[data-v-f95d32dc] .content .box:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
}
.one-col-box[data-v-f95d32dc] .content .box:nth-child(2) {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
}
.one-col-box[data-v-f95d32dc] .content .box:nth-child(3) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
}
.one-col-box[data-v-f95d32dc] .content .box:nth-child(4) {
    -ms-grid-column: 3;
    -ms-grid-row: 3;
}
}