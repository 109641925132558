@charset "UTF-8";
ul[data-v-28861170] {
  margin: 0;
  padding: 0;
}
ul li[data-v-28861170] {
  list-style: none;
  color: #666;
  font-size: 1.14286rem;
}
ul li[data-v-28861170]:not(:last-child) {
  padding-bottom: 16px;
}
ul li[data-v-28861170]::before {
  content: "⚫";
  font-size: 0.4em;
  vertical-align: middle;
  display: inline-block;
  line-height: 0.4em;
  margin-top: -2px;
  color: #666;
}