@media (min-width: 835px) {
.css-tooltip[data-v-674b2b97] {
    position: relative;
}
.css-tooltip[data-v-674b2b97]:hover::after {
    content: attr(tooltip-text);
    position: absolute;
    background: #f5f5f5;
    border-radius: 3px;
    white-space: nowrap;
    bottom: 35px;
    line-height: 16px;
    color: #000;
    padding: 6px 10px;
    right: -100%;
    border: 1px solid #c0c0c0;
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}
.css-tooltip[data-v-674b2b97]:hover::before {
    bottom: 31px;
    content: "";
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    z-index: 1;
    border: 1px solid #c0c0c0;
    border-style: solid;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-top: none;
    border-left: none;
    background: #f5f5f5;
    right: 7px;
}
[data-v-674b2b97] .main-link {
    padding-left: 16px;
    min-width: 150px;
}
[data-v-674b2b97] .main-link span:nth-child(2) {
    max-width: 285px;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 5px;
    line-height: 20px;
    vertical-align: text-top;
}
[data-v-674b2b97] .main-link:hover::before {
    left: 0;
}
[data-v-674b2b97] .main-link span.circle.blue {
    display: inline-block;
    border-radius: 14px;
    padding: 0 8px;
    background-color: #ccd6eb;
    line-height: 26px;
    color: #fff;
    margin-right: 5px;
    min-width: 26px;
    text-align: center;
}
[data-v-674b2b97] .menu-items a + a {
    margin-left: 0;
}
[data-v-674b2b97] .menu-items span {
    font-size: 15px;
}
}