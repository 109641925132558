.inner-content[data-v-02ca4510] {
  max-width: 558px;
  margin: 0 auto;
}
.inner-content h2[data-v-02ca4510] {
  margin-bottom: 3.6rem;
}
.inner-content .left-aligned[data-v-02ca4510] {
  text-align: left;
}
.inner-content .activated-view h2[data-v-02ca4510] {
  margin-bottom: 2rem;
}
.inner-content .activated-view .buttons[data-v-02ca4510] {
  margin-top: 3.1rem;
}
.inner-content h3[data-v-02ca4510] {
  font-size: 2.2rem;
  margin: 2.5rem 0;
  font-weight: 600;
  line-height: 2.6rem;
}
.inner-content h3.success[data-v-02ca4510] {
  color: #00a538;
}
.inner-content picture[data-v-02ca4510] {
  width: 73px;
  height: 73px;
  display: inline-block;
  margin: 0 auto;
}
.inner-content p[data-v-02ca4510] {
  font-size: 1.143rem;
  line-height: 1.429rem;
  margin: 1.714rem 0;
}
.inner-content .buttons[data-v-02ca4510] {
  margin-top: 5.286rem;
}
@media (min-width: 768px) {
.flex-btn[data-v-02ca4510] {
    width: auto;
    min-width: 244px;
}
.flex-btn + .flex-btn[data-v-02ca4510] {
    margin-left: 10px;
}
}