.upgrade-popup-content.reverse .choices[data-v-36036868] {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.upgrade-popup-content.reverse .choice-item[data-v-36036868]:first-child {
  margin-top: 20px;
}
.upgrade-popup-content.reverse .choice-item + .choice-item[data-v-36036868] {
  margin-top: 0;
}
.upgrade-popup-content .choices[data-v-36036868] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.upgrade-popup-content .choices + .flex-btn[data-v-36036868] {
  margin-top: 20px;
}
.upgrade-popup-content .bottom-note[data-v-36036868] {
  color: #999;
  font-size: 11px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
}
.upgrade-popup-content .choice-item[data-v-36036868] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.upgrade-popup-content .choice-item.selected > .header[data-v-36036868] {
  background-color: #003cff;
}
.upgrade-popup-content .choice-item.selected > .header .radio-button img[data-v-36036868]:first-child {
  display: none;
}
.upgrade-popup-content .choice-item.selected > .header .radio-button img[data-v-36036868]:last-child {
  display: inline-block;
}
.upgrade-popup-content .choice-item.selected > .header h3[data-v-36036868] {
  color: #fff;
}
.upgrade-popup-content .choice-item.selected > .content[data-v-36036868] {
  border-color: #003cff;
}
.upgrade-popup-content .choice-item + .choice-item[data-v-36036868] {
  margin-top: 20px;
}
.upgrade-popup-content .choice-item > .header[data-v-36036868] {
  background: #dddddd;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.upgrade-popup-content .choice-item > .header .radio-button[data-v-36036868] {
  height: 16px;
  margin: 20px 15px;
  width: 16px;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}
.upgrade-popup-content .choice-item > .header .radio-button img[data-v-36036868] {
  vertical-align: top;
}
.upgrade-popup-content .choice-item > .header .radio-button img[data-v-36036868]:last-child {
  display: none;
}
.upgrade-popup-content .choice-item > .header h3[data-v-36036868] {
  color: #333;
  margin: 8px 0 12px 0;
  font-weight: 600;
  font-size: 1.286rem;
  text-align: left;
}
.upgrade-popup-content .choice-item > .header .text[data-v-36036868] {
  margin-right: 15px;
  width: 100%;
}
.upgrade-popup-content .choice-item > .content[data-v-36036868] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid #ddd;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.upgrade-popup-content .choice-item > .content .image[data-v-36036868] {
  padding: 16px 0;
}
.upgrade-popup-content .choice-item > .content .text[data-v-36036868] {
  text-align: left;
  padding: 0 0 16px 16px;
}
.upgrade-popup-content .choice-item > .content .text ul[data-v-36036868] {
  padding-left: 25px;
  color: #212121;
  line-height: 1.4rem;
  margin-bottom: 0;
  margin-top: 1rem;
}
.upgrade-popup-content .choice-item > .content .price[data-v-36036868] {
  text-align: left;
  padding: 0 0 16px 16px;
  width: 100%;
  margin-top: 10px;
}
.upgrade-popup-content .choice-item > .content .price p[data-v-36036868]:not(.value) {
  font-style: italic;
}
.upgrade-popup-content .choice-item > .content .price .value[data-v-36036868] {
  color: #333;
  font-weight: bold;
  font-size: 26px;
  margin: 10px 0;
}
.upgrade-popup-content h2[data-v-36036868] {
  margin-bottom: 36px;
}
.upgrade-popup-content p[data-v-36036868] {
  color: #212121;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}
@media (min-width: 835px) {
.upgrade-popup-content .flex-btn[data-v-36036868] {
    width: auto;
}
.upgrade-popup-content h2[data-v-36036868] {
    margin-bottom: 48px;
}
.upgrade-popup-content .bottom-note[data-v-36036868] {
    max-width: 482px;
    margin: 20px auto 5px auto;
}
.upgrade-popup-content .choice-item > .content[data-v-36036868] {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.upgrade-popup-content .choice-item > .content > .image[data-v-36036868] {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    padding: 0;
}
.upgrade-popup-content .choice-item > .content > .text[data-v-36036868] {
    padding: 10px 16px;
    width: 100%;
}
.upgrade-popup-content .choice-item > .content > .price[data-v-36036868] {
    text-align: center;
    max-width: 252px;
    padding: 0 16px 0 0;
    margin-top: 0;
}
}