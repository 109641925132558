p.error-message[data-v-6beb97a1] {
  color: #bf0001;
  padding: 7px 0;
  font-size: 1em;
  margin-top: 0;
}
.price-block[data-v-6beb97a1] {
  text-align: center;
}
.price-block p[data-v-6beb97a1] {
  margin: 16px 0;
}
.center-strike[data-v-6beb97a1] {
  position: relative;
  white-space: nowrap;
}
.center-strike[data-v-6beb97a1]::before {
  border-top: 1px solid #666666;
  position: absolute;
  content: "";
  right: 0;
  bottom: 42%;
  left: -17%;
  width: 130%;
}
.original-price[data-v-6beb97a1] {
  font-size: 1.45rem;
  font-weight: 400;
  color: #666666;
  vertical-align: middle;
}
.discounted-price[data-v-6beb97a1] {
  color: #000;
  font-size: 1.7rem;
  font-weight: 400;
  vertical-align: middle;
}
p.success[data-v-6beb97a1] {
  margin: 0;
}
p.success > span[data-v-6beb97a1] {
  color: #00a538;
}
p.success > span.icon[data-v-6beb97a1] {
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  margin-top: -1px;
}
p.success > span.icon > img[data-v-6beb97a1] {
  vertical-align: top;
}
@media (min-width: 835px) {
.discount-applied[data-v-6beb97a1] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.discount-applied p.success[data-v-6beb97a1] {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
}
.discount-applied .price-block p[data-v-6beb97a1] {
    margin-top: 0;
}
.discount-form[data-v-6beb97a1] {
    margin-bottom: 5px;
}
[data-v-6beb97a1] input {
    min-width: 281px;
}
.error-message[data-v-6beb97a1] {
    position: absolute;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 3px;
    margin-left: 13px;
}
.error-message + .flex-btn[data-v-6beb97a1] {
    margin-top: 0;
    margin-left: 10px;
}
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
.center-strike[data-v-6beb97a1]::before {
    bottom: 26%;
}
}