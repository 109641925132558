.item[data-v-7123207e] {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.item + .item[data-v-7123207e] {
  border-top: 1px solid #dedede;
  margin-top: 10px;
  padding-top: 10px;
}
.title[data-v-7123207e] {
  color: #666;
  font-weight: 600;
  font-size: 1.14286rem;
  grid-column: 1;
}
.value[data-v-7123207e] {
  color: #212121;
  font-weight: 300;
  font-size: 1.14286rem;
  grid-column: 2;
  text-align: right;
}
.value[data-v-7123207e]:nth-child(5) {
  grid-row: 1;
}
.value[data-v-7123207e]:nth-child(6) {
  grid-row: 2;
}
.value[data-v-7123207e]:nth-child(7) {
  grid-row: 3;
}
.value[data-v-7123207e]:nth-child(8) {
  grid-row: 4;
}
@media (min-width: 835px) {
.item[data-v-7123207e] {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}
.item + .item[data-v-7123207e] {
    border-top: none;
    margin-top: 16px;
    padding-top: 0;
}
.item:not(:first-child) .title[data-v-7123207e] {
    display: none;
}
.item .title[data-v-7123207e] {
    grid-column: auto;
}
.item .value[data-v-7123207e] {
    grid-column: auto;
    grid-row: auto;
    text-align: left;
}
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
.item[data-v-7123207e] {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr;
}
.item .title[data-v-7123207e]:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
}
.item .title[data-v-7123207e]:nth-child(2) {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
}
.item .title[data-v-7123207e]:nth-child(3) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
}
.item .title[data-v-7123207e]:nth-child(4) {
    -ms-grid-column: 1;
    -ms-grid-row: 4;
}
.item .value[data-v-7123207e]:nth-child(5) {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
}
.item .value[data-v-7123207e]:nth-child(6) {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
}
.item .value[data-v-7123207e]:nth-child(7) {
    -ms-grid-column: 2;
    -ms-grid-row: 3;
}
.item .value[data-v-7123207e]:nth-child(8) {
    -ms-grid-column: 2;
    -ms-grid-row: 4;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 835px), screen and (-ms-high-contrast: none) and (min-width: 835px) {
.item[data-v-7123207e] {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
}
.item .title[data-v-7123207e]:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
}
.item .title[data-v-7123207e]:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
}
.item .title[data-v-7123207e]:nth-child(3) {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
}
.item .title[data-v-7123207e]:nth-child(4) {
    -ms-grid-column: 4;
    -ms-grid-row: 1;
}
.item .value[data-v-7123207e]:nth-child(5) {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
}
.item .value[data-v-7123207e]:nth-child(6) {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
}
.item .value[data-v-7123207e]:nth-child(7) {
    -ms-grid-column: 3;
    -ms-grid-row: 2;
}
.item .value[data-v-7123207e]:nth-child(8) {
    -ms-grid-column: 4;
    -ms-grid-row: 2;
}
}