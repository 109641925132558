.one-col-box.less-close-padding a.close[data-v-7471615e] {
  top: 12px;
  right: 12px;
}
.one-col-box[data-v-7471615e] {
  margin-left: 20px;
  margin-right: 20px;
}
.one-col-box[data-v-7471615e] .content {
  position: relative;
}
a.close[data-v-7471615e] {
  display: block;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 20px;
  right: 20px;
}
a.close span[data-v-7471615e] {
  display: block;
}
a.close picture[data-v-7471615e] {
  display: block;
}
a.close img[data-v-7471615e] {
  vertical-align: middle;
  display: inline-block;
}
a.close img[data-v-7471615e]:last-child {
  display: none;
}
a.close:hover img[data-v-7471615e]:first-child {
  display: none;
}
a.close:hover img[data-v-7471615e]:last-child {
  display: inline-block;
}
@media (min-width: 835px) {
.one-col-box.less-close-padding a.close[data-v-7471615e] {
    top: 20px;
    right: 20px;
}
.one-col-box[data-v-7471615e] {
    margin-left: auto;
    margin-right: auto;
}
}