.view-content[data-v-6914baef] {
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden; */
  overflow: hidden;
}
.view-content iframe[data-v-6914baef] {
  height: 600px;
  width: 100%;
  vertical-align: middle;
}