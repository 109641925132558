@media (min-width: 835px) {
.arrow-menu[data-v-fabf2a7a] {
    position: relative;
    display: inline-block;
}
.arrow-menu.menu-open div[data-v-fabf2a7a] {
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
.arrow-menu.menu-open .arrow-icon img[data-v-fabf2a7a]:first-child {
    display: inline-block;
}
.arrow-menu.menu-open .arrow-icon img[data-v-fabf2a7a]:last-child {
    display: none;
}
.arrow-menu div[data-v-fabf2a7a] {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    position: absolute;
    background: white;
    width: 100%;
    left: 0;
    -webkit-transition: -webkit-transform 100ms ease-in-out;
    transition: -webkit-transform 100ms ease-in-out;
    transition: transform 100ms ease-in-out;
    transition: transform 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
}
.arrow-menu div a[data-v-fabf2a7a] {
    width: 100%;
    padding: 0 16px;
    color: #212121;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    line-height: 18px;
    min-height: 50px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    word-wrap: anywhere;
    height: 0; /* ie11 fix */
    -ms-flex-flow: row wrap;
    text-decoration: none;
}
.arrow-menu div a[data-v-fabf2a7a]:hover {
    color: #003cff;
}
.arrow-menu div a[data-v-fabf2a7a]:hover::before {
    left: 0;
    width: 4px;
    height: 100%;
}
.arrow-menu > a[data-v-fabf2a7a] {
    position: relative;
    z-index: 2;
    background: #fff;
}
.arrow-menu > a span[data-v-fabf2a7a] {
    color: #003cff;
}
}