.login-left-section[data-v-2173a346] {
  background: #ffffff;
  height: 100%;
}
.login-left-section .iframe-wrapper[data-v-2173a346] {
  padding: 8px 38px 0 38px;
  line-height: 0;
}
.login-left-section .iframe-wrapper iframe[data-v-2173a346] {
  width: 100%;
  height: 100%;
  min-height: 512px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
.login-left-section .iframe-wrapper iframe[data-v-2173a346] {
    min-height: 518px;
}
}