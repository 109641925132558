section[data-v-4d739afc] {
  display: none;
}
@media (min-width: 835px) {
section[data-v-4d739afc] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 0;
    margin-top: 1px;
    position: relative;
}
section[data-v-4d739afc]::before {
    content: "";
    display: block;
    background: #f2f2f2;
    height: 1px;
    top: -1px;
    width: 100%;
    position: absolute;
    z-index: 3;
}
section > div[data-v-4d739afc]:first-child {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
}
section > div:last-child > a[data-v-4d739afc]::after {
    content: "";
    display: inline-block;
    width: 1px;
    background-color: #f2f2f2;
    height: 30px;
    vertical-align: middle;
    margin-left: 16px;
}
section span[data-v-4d739afc] {
    font-size: 15px;
}
}