[data-v-0e26f0d1] .one-col-box .content {
  padding: 20px;
}
@media (max-width: 520px) {
[data-v-0e26f0d1] .one-col-box .content {
    padding: 0 !important;
}
a.close[data-v-0e26f0d1] {
    top: 20px;
    right: 20px;
}
}