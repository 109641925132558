.input-wrapper.loading[data-v-19323830] {
  position: relative;
}
.input-wrapper.loading[data-v-19323830]::before {
  content: "";
  background: url(../img/spinner.0cbda0e8.svg);
  background-repeat: repeat;
  background-size: auto;
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}
.box p.error-message[data-v-19323830] {
  color: #bf0001;
  padding: 7px 0;
  font-size: 1em;
}
.box p.success-message[data-v-19323830] {
  color: #00a538;
  padding: 7px 0;
  font-size: 1em;
}