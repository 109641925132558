.grid[data-v-3d2a867d] {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
}
@media (min-width: 835px) {
.grid[data-v-3d2a867d] {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 80px;
}
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
.grid[data-v-3d2a867d] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 1fr 20px;
}
.grid > div[data-v-3d2a867d]:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.grid > div[data-v-3d2a867d]:nth-child(2) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
}
.grid > div[data-v-3d2a867d]:nth-child(3) {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
}
.grid > div[data-v-3d2a867d]:nth-child(4) {
    -ms-grid-row: 7;
    -ms-grid-column: 1;
}
.grid > div[data-v-3d2a867d]:nth-child(5) {
    -ms-grid-row: 9;
    -ms-grid-column: 1;
}
.grid > div[data-v-3d2a867d]:nth-child(6) {
    -ms-grid-row: 11;
    -ms-grid-column: 1;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 835px), screen and (-ms-high-contrast: none) and (min-width: 835px) {
.grid[data-v-3d2a867d] {
    display: -ms-grid;
    -ms-grid-columns: 1fr 80px 1fr 80px 1fr;
    -ms-grid-rows: 1fr 80px 1fr 80px 1fr 80px;
}
.grid > div[data-v-3d2a867d]:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.grid > div[data-v-3d2a867d]:nth-child(2) {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
}
.grid > div[data-v-3d2a867d]:nth-child(3) {
    -ms-grid-row: 5;
    -ms-grid-column: 5;
}
.grid > div[data-v-3d2a867d]:nth-child(4) {
    -ms-grid-row: 7;
    -ms-grid-column: 7;
}
.grid > div[data-v-3d2a867d]:nth-child(5) {
    -ms-grid-row: 9;
    -ms-grid-column: 9;
}
.grid > div[data-v-3d2a867d]:nth-child(6) {
    -ms-grid-row: 11;
    -ms-grid-column: 11;
}
}