.grid p.bold[data-v-c207672e] {
  font-weight: 600;
}
.grid p.centered[data-v-c207672e] {
  text-align: center;
}
.grid p.renewal[data-v-c207672e] {
  margin-bottom: 0;
}
#div-sense-status[data-v-c207672e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 835px) {
.grid.bigger-gap[data-v-c207672e] {
    grid-gap: 15px 20px;
}
.grid p.renewal[data-v-c207672e] {
    margin-bottom: 3px;
}
.desktop-fill[data-v-c207672e] {
    min-height: 50px;
}
.overview > h2[data-v-c207672e] {
    margin-bottom: 16px;
}
}