@media (min-width: 835px) {
.one-col-box.grid[data-v-4d16f7f0] .content {
    grid-gap: 0 70px;
    background-position: center;
    grid-template-columns: 1fr;
}
.one-col-box.grid[data-v-4d16f7f0] .content .flex-btn {
    width: auto;
    min-width: 106px;
}
.one-col-box.grid[data-v-4d16f7f0] .content .flex-btn + .flex-btn {
    margin-left: 10px;
}
}