@media (min-width: 835px) {
.one-col-box.grid[data-v-51665e6a] .content {
    grid-gap: 0 70px;
    background-position: center;
    background-size: 2px 80%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#f5f5f5));
    background-image: linear-gradient(#f5f5f5, #f5f5f5);
    background-repeat: no-repeat;
}
.one-col-box.grid[data-v-51665e6a] .content .flex-btn {
    width: auto;
    min-width: 106px;
}
.one-col-box.grid[data-v-51665e6a] .content .flex-btn + .flex-btn {
    margin-left: 10px;
}
}