body:not(.top-menu-open) .the-header-bar-announcement.no-shadow[data-v-1b5284ec] {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.the-header-bar-announcement[data-v-1b5284ec] {
  background: #fff;
  font-family: "FSecureOffice";
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  z-index: 3;
  position: fixed;
  width: 100%;
  top: 125px;
}
.the-header-bar-announcement div[data-v-1b5284ec] {
  text-align: center;
}
.the-header-bar-announcement span[data-v-1b5284ec] {
  color: red;
  font-weight: bold;
}
@media (min-width: 835px) {
.the-header-bar-announcement[data-v-1b5284ec] {
    top: 135px;
}
.the-header-bar-announcement.nav-shown > section[data-v-1b5284ec]:first-child picture {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.the-header-bar-announcement > section[data-v-1b5284ec] {
    min-height: 50px;
    max-width: 960px;
    margin: 0 auto;
}
}
@media (min-width: 835px) and (max-width: 991px) {
.the-header-bar-announcement > section[data-v-1b5284ec] {
    margin: 0 16px;
}
}