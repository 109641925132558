.grid[data-v-85625c14] {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;
}
.grid p[data-v-85625c14] {
  margin: 3px 0;
  font-weight: 300;
  color: #212121;
}
.grid .short p[data-v-85625c14] {
  font-size: 1.214rem;
}
.grid .short + .long[data-v-85625c14] {
  margin-top: 20px;
}
@media (min-width: 835px) {
.grid[data-v-85625c14] {
    grid-gap: 7px 20px;
    grid-template-columns: 1fr 240px;
}
.grid .short + .long[data-v-85625c14] {
    margin-top: 0;
}
.grid > div.long[data-v-85625c14] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.grid > div.short[data-v-85625c14] {
    text-align: right;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 835px), screen and (-ms-high-contrast: none) and (min-width: 835px) {
.grid[data-v-85625c14] {
    display: -ms-grid;
    -ms-grid-columns: 1fr 20px 240px;
    -ms-grid-rows: 1fr 7px 1fr 7px 1fr 7px 1fr;
}
.grid > div[data-v-85625c14]:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.grid > div[data-v-85625c14]:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}
.grid > div[data-v-85625c14]:nth-child(3) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
}
.grid > div[data-v-85625c14]:nth-child(4) {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
}
.grid > div[data-v-85625c14]:nth-child(5) {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
}
.grid > div[data-v-85625c14]:nth-child(6) {
    -ms-grid-row: 5;
    -ms-grid-column: 3;
}
.grid > div[data-v-85625c14]:nth-child(7) {
    -ms-grid-row: 7;
    -ms-grid-column: 1;
}
.grid > div[data-v-85625c14]:nth-child(8) {
    -ms-grid-row: 7;
    -ms-grid-column: 3;
}
}