.modal-popup.wide[data-v-55a3c380] .footer p:first-child {
  max-width: 400px;
  margin: 0 auto;
}
.modal-popup.wide[data-v-55a3c380] .footer p + .flex-btn {
  margin-top: 20px;
}
.modal-popup.wide[data-v-55a3c380] .footer.more-padding {
  padding: 50px 20px;
}
.modal-popup.wide[data-v-55a3c380] .footer.more-padding p + .flex-btn {
  margin-top: 40px;
}
.modal-popup.wide[data-v-55a3c380] .box {
  max-width: 640px;
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  width: 100%;
}
.modal-popup.wide[data-v-55a3c380] .box .popup-content {
  padding-bottom: 45px;
}
h2[data-v-55a3c380] {
  margin-bottom: 36px;
}
.primary[data-v-55a3c380]:disabled {
  cursor: not-allowed;
  background-color: #2693ff;
}
.flex-btn + .flex-btn[data-v-55a3c380] {
  margin-top: 10px;
}
h3[data-v-55a3c380] {
  font-size: 1.28571rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.288em;
  color: #ffac00;
}
p[data-v-55a3c380] {
  margin: 0;
  color: #666;
  line-height: 1.5rem;
}
p.teaser[data-v-55a3c380] {
  background-color: #f5f5f5;
  display: inline-block;
  font-style: italic;
  font-weight: normal;
  line-height: 33px;
  margin-top: 25px;
  padding: 0 10px;
}
.content-row[data-v-55a3c380] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto 40px auto;
  max-width: 485px;
}
.content-row p:first-child + p[data-v-55a3c380] {
  margin-top: 36px;
}
.content-row.centered[data-v-55a3c380] {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}
.content-row.valign-to-top[data-v-55a3c380] {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.content-row.no-bottom-padding[data-v-55a3c380] {
  margin-bottom: 0;
}
.icon[data-v-55a3c380] {
  width: 44px;
}
.icon + .text[data-v-55a3c380] {
  padding-left: 20px;
  width: 100%;
}
.icon img[data-v-55a3c380] {
  vertical-align: top;
}
@media (min-width: 835px) {
.modal-popup.wide[data-v-55a3c380] .box .popup-content {
    padding-bottom: 50px;
}
.modal-popup.ok[data-v-55a3c380] .box .popup-content {
    padding-bottom: 55px;
}
h3[data-v-55a3c380] {
    font-size: 1.71429rem;
}
.icon[data-v-55a3c380] {
    width: 64px;
}
.flex-btn[data-v-55a3c380] {
    width: auto;
}
.flex-btn + .flex-btn[data-v-55a3c380] {
    margin-left: 10px;
}
}