.the-maintenance-title-section[data-v-1c8b5638] {
  padding: 16px;
}
.the-maintenance-title-section h1[data-v-1c8b5638] {
  font-size: 1.71429rem;
  color: #003cff;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
}
.the-maintenance-title-section p[data-v-1c8b5638] {
  font-weight: 300;
  text-align: center;
}
.the-maintenance-title-section .img[data-v-1c8b5638] {
  width: 88px;
  height: 88px;
  margin-left: auto;
  margin-right: auto;
  background-image: url(../img/error_server.3146495e.svg);
  background-position: center;
  background-size: cover;
  margin-bottom: 10px;
}
@media (min-width: 835px) {
.the-maintenance-title-section[data-v-1c8b5638] {
    padding: 32px;
}
.the-maintenance-title-section h1[data-v-1c8b5638] {
    margin-bottom: 40px;
}
.the-maintenance-title-section p[data-v-1c8b5638] {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
.the-maintenance-title-section .img[data-v-1c8b5638] {
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
}
}