.avenue-iframe {
  height: calc(100% - 138px);
  line-height: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  margin-top: 138px;
  bottom: 0;
  left: 0;
  right: 0;
}
.avenue-iframe iframe {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
.avenue-iframe::before {
  content: "";
  display: block;
  position: absolute;
  background-image: url(../img/spinner.0cbda0e8.svg), -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0.5)));
  background-image: url(../img/spinner.0cbda0e8.svg), linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 200ms, visibility 200ms;
  transition: opacity 200ms, visibility 200ms;
}
.avenue-iframe.loading::before {
  visibility: visible;
  opacity: 1;
}
body.iframe-resizer-active {
  min-height: 100vh;
  height: auto;
}
body.iframe-resizer-active #app {
  min-height: 100vh;
  height: auto;
}
body.iframe-resizer-active #app div.base-view {
  min-height: 100vh;
  height: auto;
  padding-bottom: 0;
}
body.iframe-resizer-active #app div.base-view .top {
  padding-bottom: 0;
}
body.iframe-resizer-active #app div.base-view .avenue-iframe {
  position: relative;
}