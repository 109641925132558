.title h3[data-v-4a4b4e02] {
  margin: 0;
}
.title p[data-v-4a4b4e02] {
  margin-top: 0;
  font-weight: 400;
  color: #212121;
}
.content p[data-v-4a4b4e02] {
  margin-bottom: 0;
}
.item p[data-v-4a4b4e02] {
  font-weight: 300;
  color: #212121;
}
.item p a[data-v-4a4b4e02] {
  font-weight: 400;
}