[data-v-74916e5a] .one-col-box {
  min-height: 445px;
}
[data-v-74916e5a] .one-col-box .content {
  padding: 20px;
}
@media (min-width: 835px) {
[data-v-74916e5a] .one-col-box {
    max-width: 640px;
}
[data-v-74916e5a] .one-col-box .content {
    padding: 48px 60px 40px 60px;
}
}