.modal-popup[data-v-2b90b1e0] {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10004;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  top: 0;
}
.modal-popup.less-close-padding a.close[data-v-2b90b1e0] {
  top: 9px;
  right: 9px;
}
.modal-popup a.close[data-v-2b90b1e0] {
  display: block;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.modal-popup a.close span[data-v-2b90b1e0] {
  display: block;
}
.modal-popup a.close picture[data-v-2b90b1e0] {
  display: block;
}
.modal-popup a.close img[data-v-2b90b1e0] {
  vertical-align: middle;
  display: inline-block;
}
.modal-popup a.close img[data-v-2b90b1e0]:last-child {
  display: none;
}
.modal-popup a.close:hover img[data-v-2b90b1e0]:first-child {
  display: none;
}
.modal-popup a.close:hover img[data-v-2b90b1e0]:last-child {
  display: inline-block;
}
.modal-popup.wide > .box[data-v-2b90b1e0] {
  max-width: 900px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.modal-popup > .box[data-v-2b90b1e0] {
  background: #fff;
  position: relative;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 9px #888888;
          box-shadow: 0px 0px 9px #888888;
  max-width: 640px;
  margin: auto 20px;
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
}
.modal-popup > .box > .footer[data-v-2b90b1e0] {
  padding: 20px;
  background: #f5f5f5;
  border-top: 1px #e8e8e8 solid;
  text-align: center;
}
.modal-popup > .box > .footer p[data-v-2b90b1e0] {
  color: #212121;
  margin-bottom: 4px;
}
@media (min-width: 835px) {
.modal-popup.less-close-padding a.close[data-v-2b90b1e0] {
    top: 17px;
    right: 17px;
}
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
.modal-popup > .box[data-v-2b90b1e0] {
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    position: absolute;
    margin: auto;
    width: calc(100% - 40px);
}
}