h2[data-v-434cb546],
h3[data-v-434cb546] {
  color: black;
  text-align: left;
}
.applying-coupon-spinner[data-v-434cb546] {
  max-height: 32px;
}
.input-wrapper[data-v-434cb546] label span {
  color: #666;
  margin-bottom: 5px;
  display: inline-block;
}
.hint[data-v-434cb546] {
  display: none;
}
.hint a[data-v-434cb546] {
  text-decoration: none;
}
.hint .icon[data-v-434cb546] {
  display: inline-block;
  width: 26px;
  height: 26px;
}
.hint .icon img[data-v-434cb546] {
  vertical-align: middle;
}
.flex-row + p[data-v-434cb546]:not(.error-message) {
  margin-top: 32px;
  color: #666;
  margin-bottom: 16px;
}
.downgrade[data-v-434cb546] {
  color: red;
}
@media (min-width: 835px) {
.flex-row[data-v-434cb546] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}
.flex-row .input-wrapper[data-v-434cb546] {
    margin-right: 20px;
}
.flex-row .hint[data-v-434cb546] {
    display: block;
    width: 40%;
    min-height: 43px;
}
.flex-row .hint .css-tooltip[data-v-434cb546] {
    position: relative;
}
.flex-row .hint .css-tooltip[data-v-434cb546]:hover::after {
    content: attr(tooltip-text);
    position: absolute;
    background: #ffffff;
    border-radius: 3px;
    white-space: wrap;
    top: 35px;
    line-height: 16px;
    color: #000;
    padding: 6px 10px;
    border: 1px solid #f5f5f5;
    -webkit-box-shadow: 0px 0px 9px #888888;
            box-shadow: 0px 0px 9px #888888;
    width: 190px;
    height: 200px;
    margin-top: -260px;
    margin-left: -20px;
    z-index: 10;
    -webkit-transition: opacity 0.1s ease;
    transition: opacity 0.1s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
}
.flex-row .hint a[data-v-434cb546] {
    display: inline-block;
    line-height: 0;
}
.flex-row .hint a[data-v-434cb546]::after {
    opacity: 0;
    visibility: hidden;
    content: "";
    border: 1px solid #f5f5f5;
    background-image: url(../img/popup.bd98fb58.png), -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
    background-image: url(../img/popup.bd98fb58.png), linear-gradient(#fff, #fff);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-box-shadow: 0px 0px 9px #888888;
            box-shadow: 0px 0px 9px #888888;
    width: 217px;
    height: 217px;
    display: block;
    position: absolute;
    margin-top: -260px;
    margin-left: -20px;
    z-index: 10;
    -webkit-transition: opacity 0.1s ease;
    transition: opacity 0.1s ease;
}
.flex-row .hint a[data-v-434cb546]:hover::after {
    opacity: 1;
    visibility: visible;
}
.flex-row .hint a span.text[data-v-434cb546] {
    line-height: 0.5rem;
}
}
.grid-4[data-v-434cb546] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  color: #666;
  margin-bottom: 40px;
}
.grid-4 > div[data-v-434cb546] {
  padding: 10px 0;
  border-bottom: 1px solid #dedede;
}
.grid-4 > div > span[data-v-434cb546] {
  font-weight: 600;
  font-size: 1rem;
}
.grid-4 > div[data-v-434cb546]:nth-child(1) {
  grid-row: 1;
  grid-column: 1;
}
.grid-4 > div[data-v-434cb546]:nth-child(2) {
  grid-row: 1;
  grid-column: 2;
}
.grid-4 > div[data-v-434cb546]:nth-child(3) {
  grid-row: 1;
  grid-column: 3;
}
.grid-4 > div[data-v-434cb546]:nth-child(4) {
  grid-row: 1;
  grid-column: 4;
}
.grid-4 > div[data-v-434cb546]:nth-child(5) {
  grid-row: 2;
  grid-column: 1;
}
.grid-4 > div[data-v-434cb546]:nth-child(6) {
  grid-row: 2;
  grid-column: 2;
}
.grid-4 > div[data-v-434cb546]:nth-child(7) {
  grid-row: 2;
  grid-column: 3;
}
.grid-4 > div[data-v-434cb546]:nth-child(8) {
  grid-row: 2;
  grid-column: 4;
}