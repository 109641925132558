.settings-content[data-v-15a36016] {
  padding: 33px 40px;
}
.settings-content[data-v-15a36016] h2 {
  margin-top: 0;
  margin-bottom: 38px;
}
.settings-content[data-v-15a36016] h3 {
  line-height: 1.6rem;
}
.settings-content[data-v-15a36016] p {
  line-height: 1.6rem;
  color: #212121;
}
.grid[data-v-15a36016] {
  display: grid;
  grid-gap: 24px 20px;
  grid-template-columns: 1fr;
}
.grid .item[data-v-15a36016] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.grid .item[data-v-15a36016] > .content {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}
@media (min-width: 835px) {
.settings-content[data-v-15a36016] {
    padding: 40px;
}
.grid[data-v-15a36016] {
    grid-template-columns: repeat(3, 1fr);
}
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
.grid[data-v-15a36016] {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
}
.grid[data-v-15a36016] .item {
    -ms-flex-flow: row wrap;
}
.grid[data-v-15a36016] .item .title {
    padding-bottom: 3px;
}
.grid[data-v-15a36016] .item:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    padding-bottom: 20px;
}
.grid[data-v-15a36016] .item:nth-child(2) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 835px), screen and (-ms-high-contrast: none) and (min-width: 835px) {
.grid[data-v-15a36016] {
    -ms-grid-columns: 1fr 1fr 1fr;
}
.grid[data-v-15a36016] .item .title {
    padding-bottom: 10px;
}
.grid[data-v-15a36016] .item:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    padding-bottom: 0;
}
.grid[data-v-15a36016] .item:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
}
}