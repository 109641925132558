.voucher-item[data-v-61c98713] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-box-shadow: 0 2px 10px -5px #888;
          box-shadow: 0 2px 10px -5px #888;
  border-radius: 6px;
}
.voucher-item .heading[data-v-61c98713] {
  font-size: 0.85714rem;
  line-height: 1.2rem;
  background: #f5f5f5;
  color: #666;
  font-style: italic;
  padding: 10px;
  min-height: 36px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.voucher-item .content[data-v-61c98713] {
  padding: 16px 8px 10px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.voucher-item .voucher[data-v-61c98713] {
  color: #6621cc;
  font-weight: 600;
  line-height: 1.14286rem;
  font-size: 1.14286rem;
}
@media (min-width: 835px) {
.voucher-item .heading[data-v-61c98713] {
    font-size: 1rem;
    line-height: 1.4rem;
}
.voucher-item .voucher[data-v-61c98713] {
    font-size: 1.71429rem;
    line-height: 1rem;
}
}