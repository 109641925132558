.sense-howto.settings-content {
  padding: 33px 40px;
}
.sense-howto.settings-content > h2 {
  margin-bottom: 16px;
}
.sense-howto.settings-content li {
  margin-top: 0;
  margin-bottom: 12px;
  color: #212121;
  line-height: 1.571rem;
  font-size: 1.1rem;
}
.sense-howto.settings-content .messages p {
  font-weight: 600;
}
.sense-howto.settings-content .messages .ok {
  color: #00a538;
}
.sense-howto.settings-content .messages .error {
  color: #bf0001;
}
@media (min-width: 768px) {
.sense-howto.settings-content {
    padding: 40px;
}
.sense-howto.settings-content > h2 {
    margin-bottom: 13px;
}
.sense-howto.settings-content p {
    margin-bottom: 20px;
}
.sense-howto.settings-content p a {
    white-space: nowrap;
}
}