.input-wrapper.loading[data-v-7127b634] {
  position: relative;
}
.input-wrapper.loading[data-v-7127b634]::before {
  content: "";
  background: url(../img/spinner.0cbda0e8.svg);
  background-repeat: repeat;
  background-size: auto;
  position: absolute;
  z-index: 2;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
  bottom: 0;
  height: 50px;
}
.input-wrapper.error input[data-v-7127b634] {
  border-color: #bf0001;
}
.input-wrapper + .flex-btn[data-v-7127b634] {
  margin-top: 40px;
}
.input-wrapper + p[data-v-7127b634]:not(.error-message) {
  margin-top: 32px;
}
@media (min-width: 835px) {
.input-wrapper.one-line-desktop[data-v-7127b634] {
    width: auto;
    display: inline-block;
}
.input-wrapper.one-line-desktop[data-v-7127b634] + .flex-btn {
    margin-top: 0;
    margin-left: 10px;
}
.input-wrapper.one-line-desktop[data-v-7127b634] input {
    width: auto;
}
.input-wrapper.one-line-desktop[data-v-7127b634] input + .flex-btn {
    margin-top: 0;
    margin-left: 10px;
}
}