@media (min-width: 835px) {
a[data-v-83c4fee2] {
    text-decoration: none;
    display: inline-block;
    line-height: 50px;
    font-size: 15px;
    color: #003cff;
}
a[data-v-83c4fee2]:visited {
    color: #003cff;
}
a + a[data-v-83c4fee2] {
    margin-left: 20px;
}
a + .narrow-menu[data-v-83c4fee2] {
    margin-left: 20px;
    min-width: 162px;
}
a + .narrow-menu a[data-v-83c4fee2] {
    width: 100%;
}
a.arrow-menu.menu-open[data-v-83c4fee2] img:first-child {
    display: inline-block;
}
a.arrow-menu.menu-open[data-v-83c4fee2] img:last-child {
    display: none;
}
a[data-v-83c4fee2]:hover {
    position: relative;
}
a[data-v-83c4fee2]:hover::before {
    content: "";
    display: block;
    position: absolute;
    background: #003cff;
    width: 100%;
    height: 3px;
    bottom: 0;
}
a[data-v-83c4fee2] .arrow-icon {
    width: 20px;
    height: 20px;
    float: right;
    margin-left: 5px;
    margin-top: -2px;
}
a[data-v-83c4fee2] .arrow-icon img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}
a[data-v-83c4fee2] .arrow-icon img:first-child {
    display: none;
}
}