.apply-coupon i[data-v-388b3686] {
  color: #212121;
}
.apply-coupon p[data-v-388b3686] {
  color: #212121;
}
.apply-coupon .grid[data-v-388b3686] {
  margin-bottom: 0;
}
.apply-coupon .grid + p[data-v-388b3686] {
  margin-bottom: 30px;
  margin-top: 10px;
}
.apply-coupon .grid > div > span[data-v-388b3686] {
  font-weight: 700;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(1) {
  grid-row: 1;
  grid-column: 1;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(2) {
  grid-row: 4;
  grid-column: 1;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(3) {
  grid-row: 7;
  grid-column: 1;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(4) {
  grid-row: 1;
  grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(5) {
  grid-row: 4;
  grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(6) {
  grid-row: 7;
  grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(7) {
  grid-row: 2;
  grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(8) {
  grid-row: 5;
  grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(9) {
  grid-row: 8;
  grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(10) {
  grid-row: 3;
  grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(11) {
  grid-row: 6;
  grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(12) {
  grid-row: 9;
  grid-column: 2;
}
.apply-coupon .grid > div:nth-child(n+7) > span[data-v-388b3686] {
  font-weight: 400;
  font-style: italic;
}
.success-notice + h3[data-v-388b3686] {
  margin-top: 40px;
}
.success-notice h3[data-v-388b3686] {
  color: #00a538;
  font-size: 1.71429rem;
  margin: 0 0 5px 0;
}
.success-notice h3 > span.icon[data-v-388b3686] {
  width: 26px;
  height: 26px;
  vertical-align: top;
  display: inline-block;
}
.success-notice h3 > span.icon > img[data-v-388b3686] {
  vertical-align: top;
}
input[data-v-388b3686] {
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
}
input.error[data-v-388b3686] {
  border-color: #bf0001;
}
p.error-message[data-v-388b3686] {
  color: #bf0001;
  padding: 7px 0;
  font-size: 1em;
  margin-top: 0;
}
h2[data-v-388b3686] {
  font-size: 2rem;
  color: #003cff;
  font-weight: 600;
  line-height: 1.21886em;
  margin: 20px 0 40px 0;
}
h3[data-v-388b3686] {
  font-size: 1.57143rem;
  font-weight: 600;
  margin: 20px 0;
}
.flex-btn + .flex-btn[data-v-388b3686] {
  margin-top: 10px;
}
.grid[data-v-388b3686] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  color: #666;
  background: #f5f5f5;
  padding: 10px 12px;
  margin-bottom: 40px;
}
.grid > div > span[data-v-388b3686] {
  font-weight: 600;
  font-size: 1rem;
}
.grid > div[data-v-388b3686]:nth-child(1) {
  grid-row: 1;
  grid-column: 1;
}
.grid > div[data-v-388b3686]:nth-child(2) {
  grid-row: 2;
  grid-column: 1;
}
.grid > div[data-v-388b3686]:nth-child(3) {
  grid-row: 3;
  grid-column: 1;
}
.grid > div[data-v-388b3686]:nth-child(4) {
  grid-row: 1;
  grid-column: 2;
}
.grid > div[data-v-388b3686]:nth-child(5) {
  grid-row: 2;
  grid-column: 2;
}
.grid > div[data-v-388b3686]:nth-child(6) {
  grid-row: 3;
  grid-column: 2;
}
.input-wrapper.loading[data-v-388b3686] {
  position: relative;
}
.input-wrapper.loading[data-v-388b3686]::before {
  content: "";
  background: url(../img/spinner.0cbda0e8.svg);
  background-repeat: repeat;
  background-size: auto;
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
  height: 50px;
}
.input-wrapper + .flex-btn[data-v-388b3686] {
  margin-top: 40px;
}
.input-wrapper + p[data-v-388b3686]:not(.error-message) {
  margin-top: 32px;
  color: #666;
  margin-bottom: 16px;
}
@media (min-width: 835px) {
.apply-coupon .grid[data-v-388b3686] {
    border-bottom: 1px solid #dedede;
}
.apply-coupon .grid > div.title[data-v-388b3686] {
    grid-row: auto;
    grid-column: auto;
    border-bottom: 1px solid #dedede;
}
.apply-coupon .grid > div.value[data-v-388b3686] {
    grid-row: auto;
    grid-column: auto;
    background: none;
    border-top: none;
    border-bottom: none;
}
.view-content[data-v-388b3686] {
    text-align: left;
}
h2[data-v-388b3686] {
    font-size: 2.57143rem;
}
h3[data-v-388b3686] {
    text-align: left;
}
p[data-v-388b3686] {
    text-align: left;
}
.input-wrapper[data-v-388b3686] {
    text-align: left;
}
.input-wrapper.limited-width[data-v-388b3686] {
    max-width: 345px;
}
.input-wrapper.limited-width.loading[data-v-388b3686]::before {
    max-width: 345px;
}
input[data-v-388b3686] {
    width: auto;
    min-width: 350px;
}
.grid[data-v-388b3686] {
    text-align: left;
    background: none;
    border-top: none;
    border-bottom: none;
    padding: 0;
}
.grid > div[data-v-388b3686] {
    padding: 12px 10px;
}
.grid > div.value[data-v-388b3686] {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    background: #f5f5f5;
}
.grid > div.value > span[data-v-388b3686] {
    font-weight: 500;
}
.grid > div.title[data-v-388b3686] {
    padding-top: 0;
}
.grid > div > span[data-v-388b3686] {
    font-size: 1.14286rem;
}
.flex-btn[data-v-388b3686] {
    width: auto;
    min-width: 145px;
}
.flex-btn + .flex-btn[data-v-388b3686] {
    margin-top: 0;
    margin-left: 10px;
}
.grid[data-v-388b3686] {
    grid-template-columns: repeat(3, 1fr);
}
.grid > div.title[data-v-388b3686] {
    grid-row: auto;
    grid-column: auto;
}
.grid > div.value[data-v-388b3686] {
    grid-row: auto;
    grid-column: auto;
}
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
.grid[data-v-388b3686] {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr;
}
.grid > div[data-v-388b3686]:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.grid > div[data-v-388b3686]:nth-child(2) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
}
.grid > div[data-v-388b3686]:nth-child(3) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
}
.grid > div[data-v-388b3686]:nth-child(4) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
}
.grid > div[data-v-388b3686]:nth-child(5) {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
}
.grid > div[data-v-388b3686]:nth-child(6) {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(2) {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(3) {
    -ms-grid-row: 7;
    -ms-grid-column: 1;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(4) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(5) {
    -ms-grid-row: 4;
    -ms-grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(6) {
    -ms-grid-row: 7;
    -ms-grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(7) {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(8) {
    -ms-grid-row: 5;
    -ms-grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(9) {
    -ms-grid-row: 8;
    -ms-grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(10) {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(11) {
    -ms-grid-row: 6;
    -ms-grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(12) {
    -ms-grid-row: 9;
    -ms-grid-column: 2;
}
}
@media screen and (-ms-high-contrast: active) and (min-width: 835px), screen and (-ms-high-contrast: none) and (min-width: 835px) {
.grid[data-v-388b3686] {
    -ms-grid-columns: 1fr 1fr 1fr;
}
.grid > div[data-v-388b3686]:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.grid > div[data-v-388b3686]:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
}
.grid > div[data-v-388b3686]:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}
.grid > div[data-v-388b3686]:nth-child(4) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
}
.grid > div[data-v-388b3686]:nth-child(5) {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
}
.grid > div[data-v-388b3686]:nth-child(6) {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(4) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(5) {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(6) {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(7) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(8) {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(9) {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(10) {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(11) {
    -ms-grid-row: 4;
    -ms-grid-column: 2;
}
.apply-coupon .grid > div[data-v-388b3686]:nth-child(12) {
    -ms-grid-row: 4;
    -ms-grid-column: 3;
}
}