@charset "UTF-8";
[data-v-17e58d00] .one-col-box .content {
  padding: 20px;
}
@media (max-width: 520px) {
[data-v-17e58d00] .one-col-box .content {
    padding: 0 !important;
}
a.close[data-v-17e58d00] {
    top: 20px;
    right: 20px;
}
}
.popup-content[data-v-17e58d00] {
  padding: 32px 20px 20px 20px;
}
.popup-content h2[data-v-17e58d00] {
  color: #003cff;
  font-size: 2.5em;
  line-height: 1.21886em;
  margin-top: 0;
  font-weight: 600;
  text-align: center;
}
.popup-content ul[data-v-17e58d00] {
  padding: 0 64px;
  list-style: none;
}
.popup-content ul li[data-v-17e58d00] {
  font-size: 1.2em;
  padding-bottom: 33px;
}
.popup-content ul li[data-v-17e58d00] :before {
  content: "✓";
  display: block;
  float: left;
  margin-left: -25px;
}