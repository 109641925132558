[data-v-3d3c87fa] .one-col-box {
  min-height: 445px;
}
[data-v-3d3c87fa] .one-col-box .content {
  padding: 20px;
}
@media (min-width: 835px) {
[data-v-3d3c87fa] .one-col-box {
    max-width: 480px;
}
[data-v-3d3c87fa] .one-col-box .content {
    padding: 48px 60px 40px 60px;
}
}