div.inner-content[data-v-21932756] {
  text-align: left;
}
div.inner-content h2[data-v-21932756] {
  font-size: 2rem;
}
ol[data-v-21932756] {
  list-style: none;
  counter-reset: li;
  padding-left: 0;
}
ol li[data-v-21932756]:before {
  counter-increment: li;
  content: counter(li) ". ";
}
.list-wrapper[data-v-21932756] {
  padding: 0 0 10px 0;
}
.list-wrapper ul[data-v-21932756] {
  list-style: none;
  padding-left: 0;
}
.list-wrapper ul li[data-v-21932756] {
  background: #f5f5f5;
  border-radius: 3px;
  padding: 6px 13px;
  text-align: left;
}
.list-wrapper ul li + li[data-v-21932756] {
  margin-top: 10px;
}
div.footer-message[data-v-21932756] {
  padding: 0 0 20px;
}
@media (min-width: 768px) {
div.inner-content[data-v-21932756] {
    text-align: center;
}
div.inner-content h2[data-v-21932756] {
    font-size: 2.2rem;
}
.left-aligned[data-v-21932756] {
    text-align: left;
}
.flex-btn[data-v-21932756] {
    width: auto;
}
.list-wrapper[data-v-21932756] {
    padding: 10px 40px 30px 40px;
}
}