.settings-content[data-v-156f3190] {
  padding: 40px;
}
.settings-content + .settings-content[data-v-156f3190] {
  border-top: 1px solid #f2f2f2;
}
.settings-content[data-v-156f3190] p {
  color: #666;
  font-size: 1.14286rem;
}
.settings-content[data-v-156f3190]:last-child {
  margin-bottom: 5px;
}
.settings-content > h2[data-v-156f3190] {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.429rem;
  font-weight: 600;
  line-height: 1.21886em;
}