input[data-v-2d998ef9] {
  border-radius: 0;
  width: 100%;
  min-height: 50px;
  line-height: 50px;
  border: 1px solid #ccc;
  color: #212121;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 1.14286rem;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
}
input.error[data-v-2d998ef9] {
  border-color: #bf0001;
}
@media (min-width: 835px) {
input.one-line-desktop[data-v-2d998ef9] {
    width: auto;
}
input.one-line-desktop + .flex-btn[data-v-2d998ef9] {
    margin-top: 0;
    margin-left: 10px;
}
}