@media (min-width: 835px) {
.container[data-v-e320bffe] {
    text-align: center;
}
.container h1[data-v-e320bffe] {
    padding-bottom: 15px;
}
.container .icon img[data-v-e320bffe] {
    width: 21px;
    margin-bottom: -6px;
}
.container .icon-step[data-v-e320bffe] {
    color: white;
    background-color: #2693ff;
    padding: 2px 6px 2px 6px;
    border-radius: 18px;
    font-size: 0.75rem;
}
.container .flex-btn[data-v-e320bffe] {
    width: auto;
    margin-top: 16px;
}
.container .tab[data-v-e320bffe] {
    border: 1px solid #c5c5c5;
    width: 33.33%;
    float: left;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
}
.container .tab-middle[data-v-e320bffe] {
    border-left: 0;
    border-right: 0;
}
.container .tab-active[data-v-e320bffe] {
    border-bottom: 0;
}
.container .tab-content[data-v-e320bffe] {
    border: 1px solid #c5c5c5;
    border-top: 0;
    display: block;
    margin-right: 1px;
    padding-top: 70px;
    padding-bottom: 20px;
}
.container .tab-content .step-3[data-v-e320bffe] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.container .tab-content .step-3 div[data-v-e320bffe] {
    -webkit-box-flex: 50%;
        -ms-flex: 50%;
            flex: 50%;
}
.container .tab-content .step-3 .step-3-desc[data-v-e320bffe] {
    text-align: left;
    padding-right: 20px;
}
.container .tab-content .step-3 .step-3-desc h3[data-v-e320bffe] {
    font-size: large;
}
.container .tab-content .step-3 .step-3-desc .btn-action[data-v-e320bffe] {
    text-align: right;
}
}