@media (min-width: 835px) {
.container[data-v-5b7ba078] {
    text-align: center;
}
.container h1[data-v-5b7ba078] {
    padding-bottom: 15px;
}
.container .icon img[data-v-5b7ba078] {
    width: 21px;
    margin-bottom: -6px;
}
.container .icon-step[data-v-5b7ba078] {
    color: white;
    background-color: #2693ff;
    padding: 2px 6px 2px 6px;
    border-radius: 18px;
    font-size: 0.75rem;
}
.container .flex-btn[data-v-5b7ba078] {
    width: auto;
    margin-top: 16px;
}
.container .tag[data-v-5b7ba078] {
    background-color: #00a538;
    color: white;
    border-radius: 5px;
    padding: 0px 6px;
    font-size: 0.75rem;
}
}